import { generatePath } from "react-router";
import {
  DASHBOARD_PATH,
  ORDER_PATH,
  VERIFICATION_PATH,
  SIGNUP_PATH
} from "../../routes";
import K from "../../constants";

const ShortenedLinkTypes = Object.freeze({
  Authorized: "Authorized",
  Expired: "Expired",
  Invalid: "Invalid"
});

const isAuthorized = ({ __typename }) =>
  __typename === ShortenedLinkTypes.Authorized;
const isExpired = ({ __typename }) => __typename === ShortenedLinkTypes.Expired;
const isInvalid = ({ __typename }) => __typename === ShortenedLinkTypes.Invalid;

const getRedirectionUrlFromShortenedLinkAction = shortenedLinkAction => {
  let redirectionUrl = null;
  switch (shortenedLinkAction?.__typename) {
    case K.shortenedLinkActions.createSession:
      redirectionUrl = DASHBOARD_PATH;
      break;
    case K.shortenedLinkActions.showSelector:
      redirectionUrl = VERIFICATION_PATH;
      break;
    case K.shortenedLinkActions.showOrder:
      redirectionUrl = `${generatePath(ORDER_PATH, {
        id: shortenedLinkAction.orderId
      })}`;
      break;
    case K.shortenedLinkActions.showOrderCards:
      redirectionUrl = `${generatePath(ORDER_PATH, {
        id: shortenedLinkAction.orderId
      })}?process=${K.process.changePaymentCard}&gateway=${
        K.mufasaGateways.assignCard
      }`;
      break;
    case K.shortenedLinkActions.showInvoicesPaymentProcess:
    case K.shortenedLinkActions.showPaymentPlanPaymentProcess:
      redirectionUrl = `${generatePath(ORDER_PATH, {
        id: shortenedLinkAction.orderId
      })}?process=${K.process.payment}&step=${K.steps.summary}`;
      if (shortenedLinkAction.amount) {
        redirectionUrl = `${redirectionUrl}&amount=${shortenedLinkAction.amount}`;
      }
      if (shortenedLinkAction.invoiceNumbers) {
        redirectionUrl = `${redirectionUrl}&invoice_numbers=${shortenedLinkAction.invoiceNumbers}`;
      }
      break;
    case K.shortenedLinkActions.showAmortizationProcess:
      redirectionUrl = `${generatePath(ORDER_PATH, {
        id: shortenedLinkAction.orderId
      })}?process=${K.process.amortization}&step=${K.steps.summary}`;
      if (shortenedLinkAction.amount) {
        redirectionUrl = `${redirectionUrl}&amount=${shortenedLinkAction.amount}`;
      }
      break;
    case K.shortenedLinkActions.showConversionProcess:
      redirectionUrl = `${generatePath(ORDER_PATH, {
        id: shortenedLinkAction.orderId
      })}?process=${K.process.conversion}&step=${K.steps.selectPlan}`;
      break;
    case K.shortenedLinkActions.renderService:
      redirectionUrl = `${generatePath(ORDER_PATH, {
        id: shortenedLinkAction.orderId
      })}/render-service`;
      break;
    case K.shortenedLinkActions.addBankAccountToRefundProcess:
      redirectionUrl = `${generatePath(ORDER_PATH, {
        id: shortenedLinkAction.orderId
      })}/bank-account-refund`;
      break;
    case K.shortenedLinkActions.verifyCard:
      redirectionUrl = `${generatePath(ORDER_PATH, {
        id: shortenedLinkAction.orderId
      })}?process=${K.process.verifyCard}`;
      break;
    case K.shortenedLinkActions.showReferralSignUp:
      redirectionUrl = SIGNUP_PATH;
      break;
    case K.shortenedLinkActions.flexiConversionProcess:
      redirectionUrl = `${generatePath(ORDER_PATH, {
        id: shortenedLinkAction.orderId
      })}?process=${K.process.flexiConversion}&step=${K.steps.flexiSelectPlan}`;
      break;
    default:
      break;
  }
  return redirectionUrl;
};

const buildTrackingEventName = (shortenedLinkAction, eventNames) => {
  switch (shortenedLinkAction?.__typename) {
    case K.shortenedLinkActions.flexiConversionProcess:
      return eventNames.flexiConversion.fromEmail;
    default:
      return null;
  }
};

export default {
  isAuthorized,
  isExpired,
  isInvalid,
  getRedirectionUrlFromShortenedLinkAction,
  buildTrackingEventName
};
